import React, { useState, useEffect } from "react";
import { getFirestore, collection, addDoc, updateDoc, doc } from "firebase/firestore";
import { createUserWithEmailAndPassword, updatePassword } from "firebase/auth";  // Firebase Auth imports
import { auth } from "../../firebaseConfig";  // Ensure your firebaseConfig exports auth
import "../css/CreateUser.css";  // Optional: Style the form

const CreateUser = ({ user, closeModal }) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",  // Optional field
    city: "",  // Optional field
    state: "",  // Optional field
    street: "",  // Optional field
    zipCode: "",  // Optional field
    role: "user",  // Default role
    password: "",  // Add password field
  });

  const db = getFirestore();

  useEffect(() => {
    if (user) {
      setFormData({ ...user, password: "" });  // Set existing data for editing but keep password empty
    }
  }, [user]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (user) {
      // Edit the existing user details but only update password if it's provided
      if (formData.password) {
        const userCredential = await createUserWithEmailAndPassword(auth, formData.email, formData.password);
        await updatePassword(userCredential.user, formData.password);
      }

      // Update other fields in Firestore
      await updateDoc(doc(db, "users", user.id), {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        phoneNumber: formData.phoneNumber || "",  // Make optional
        city: formData.city || "",  // Make optional
        state: formData.state || "",  // Make optional
        street: formData.street || "",  // Make optional
        zipCode: formData.zipCode || "",  // Make optional
        role: formData.role,
      });

      alert("User updated successfully!");
    } else {
      // Create a new user and register them in Firebase Authentication
      try {
        const userCredential = await createUserWithEmailAndPassword(auth, formData.email, formData.password);
        await addDoc(collection(db, "users"), {
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          phoneNumber: formData.phoneNumber || "",  // Optional field
          city: formData.city || "",  // Optional field
          state: formData.state || "",  // Optional field
          street: formData.street || "",  // Optional field
          zipCode: formData.zipCode || "",  // Optional field
          role: formData.role,
          uid: userCredential.user.uid,  // Store Firebase UID in Firestore
        });

        alert("User created successfully!");
      } catch (error) {
        console.error("Error creating user: ", error);
        alert("Failed to create user. Check your inputs and try again.");
      }
    }
    closeModal();  // Close the modal after creating or updating
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <h2>{user ? "Edit User" : "Create User"}</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            name="firstName"
            placeholder="First Name"
            value={formData.firstName}
            onChange={handleInputChange}
            required
          />
          <input
            type="text"
            name="lastName"
            placeholder="Last Name"
            value={formData.lastName}
            onChange={handleInputChange}
            required
          />
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleInputChange}
            required
          />
          <input
            type="password"
            name="password"
            placeholder="Password"
            value={formData.password}
            onChange={handleInputChange}
            required={!user}  // Require password only when creating a new user
          />
          <input
            type="text"
            name="phoneNumber"
            placeholder="Phone Number"
            value={formData.phoneNumber}
            onChange={handleInputChange}
          />
          <input
            type="text"
            name="street"
            placeholder="Street"
            value={formData.street}
            onChange={handleInputChange}
          />
          <input
            type="text"
            name="city"
            placeholder="City"
            value={formData.city}
            onChange={handleInputChange}
          />
          <input
            type="text"
            name="state"
            placeholder="State"
            value={formData.state}
            onChange={handleInputChange}
          />
          <input
            type="text"
            name="zipCode"
            placeholder="Zip Code"
            value={formData.zipCode}
            onChange={handleInputChange}
          />
          <button type="submit">{user ? "Update User" : "Create User"}</button>
        </form>
        <button onClick={closeModal}>Close</button>
      </div>
    </div>
  );
};

export default CreateUser;
