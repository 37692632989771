import React from "react";
import Sidebar from "./Sidebar";  // Import the sidebar component
import "../css/AdminLayout.css";  // Optional CSS for layout

const AdminLayout = ({ children }) => {
  return (
    <div className="admin-layout">
      <Sidebar />  {/* Sidebar will be displayed on all admin pages */}
      <div className="admin-content">
        {children}  {/* This is where the main content of each page will go */}
      </div>
    </div>
  );
};

export default AdminLayout;
