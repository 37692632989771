import React, { useState, useEffect } from "react";
import { getFirestore, collection, getDocs, query, where } from "firebase/firestore";
import axios from "axios";  // Axios for making API calls
import AdminLayout from './components/AdminLayout';
import './css/AdminPanel.css';  // Adjust the path if necessary

const AdminPanel = () => {
  const [twilioNumbers, setTwilioNumbers] = useState([]);  // State to store assigned numbers
  const [selectedNumber, setSelectedNumber] = useState("");   // State for the selected dropdown value
  const [filterType, setFilterType] = useState("all");        // State to determine if "All logs" or "By Date"
  const [fromDate, setFromDate] = useState("");               // State for 'from' date
  const [toDate, setToDate] = useState("");                   // State for 'to' date
  const [messageLogs, setMessageLogs] = useState([]);         // State for message logs
  const [stats, setStats] = useState({ sent: 0, received: 0, delivered: 0, failed: 0 });  // Add failed messages count
  const [loading, setLoading] = useState(false);              // State for loading spinner or message
  const [error, setError] = useState(null);                   // State for error handling
  const [showReplyModal, setShowReplyModal] = useState(false);  // State to control reply modal visibility
  const [replyMessage, setReplyMessage] = useState("");        // State for the reply message input
  const [replyToNumber, setReplyToNumber] = useState("");      // State for the number to reply to

  const db = getFirestore();

  // Fetch all Twilio numbers from Firebase
  useEffect(() => {
    const fetchTwilioNumbers = async () => {
      try {
        const numbersCollection = collection(db, "twilioNumbers");
        const numberSnapshot = await getDocs(numbersCollection);
        const numbersList = numberSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setTwilioNumbers(numbersList);
      } catch (error) {
        console.error("Error fetching Twilio numbers:", error);
      }
    };
    
    fetchTwilioNumbers();
  }, [db]);

  // Function to fetch message logs from the backend when a number is selected
  const fetchMessageLogs = async () => {
    if (!selectedNumber) {
      alert("Please select a number.");
      return;
    }

    setLoading(true);  // Show loading state
    setError(null);    // Reset error state

    try {
      // Set up parameters for the API call
      const params = { number: selectedNumber };

      if (filterType === "byDate" && fromDate && toDate) {
        params.fromDate = fromDate;
        params.toDate = toDate;
      }

      // Call the API to fetch message logs
      const response = await axios.get(`https://serv1.mycampaignkit.com/api/twilioMessages`, { params });

      // Sort message logs by date (latest at the top)
      const sortedLogs = response.data.sort((a, b) => new Date(b.dateSent) - new Date(a.dateSent));

      setMessageLogs(sortedLogs);  // Set the fetched logs in state

      // Calculate the message stats
      const sent = sortedLogs.filter(log => log.direction === "outbound-api").length;
      const received = sortedLogs.filter(log => log.direction === "inbound").length;
      const delivered = sortedLogs.filter(log => log.status === "delivered").length;
      const failed = sortedLogs.filter(log => log.status === "failed" || log.status === "undelivered").length;
      setStats({ sent, received, delivered, failed });
    } catch (error) {
      console.error("Error fetching message logs:", error);
      setError("Failed to fetch message logs. Please check the API or CORS settings.");
    } finally {
      setLoading(false);  // Stop loading when done
    }
  };

  // Clear the form and message logs
  const clearFilters = () => {
    setSelectedNumber("");
    setFilterType("all");
    setFromDate("");
    setToDate("");
    setMessageLogs([]);
    setStats({ sent: 0, received: 0, delivered: 0, failed: 0 });
  };

  // Function to handle the reply modal visibility
  const openReplyModal = (number) => {
    setReplyToNumber(number);
    setShowReplyModal(true);
  };

  // Function to send a reply message
  const sendReplyMessage = async () => {
    if (!replyMessage) {
      alert("Please enter a message to send.");
      return;
    }

    try {
      // Call the API to send a reply message
      const response = await axios.post(`https://serv1.mycampaignkit.com/api/sendReply`, {
        to: replyToNumber,
        from: selectedNumber,  // Use the selected number as the sender
        body: replyMessage
      });

      alert("Message sent successfully!");
      setReplyMessage("");  // Clear the message input
      setShowReplyModal(false);  // Close the modal
    } catch (error) {
      console.error("Error sending reply message:", error);
      setError("Failed to send message. Please check the API or CORS settings.");
    }
  };

  return (
    <AdminLayout>
      <div className="admin-panel full-width">
        <h2>Twilio Numbers Assigned to Users</h2>
        {twilioNumbers.length > 0 ? (
          <div>
            <div className="dropdown-filters">
              {/* Select a Number */}
              <div>
                <label htmlFor="numbers-dropdown">Select a Number: </label>
                <select
                  id="numbers-dropdown"
                  value={selectedNumber}
                  onChange={(e) => setSelectedNumber(e.target.value)}
                  className="dropdown"
                >
                  <option value="" disabled>Select a number</option>
                  {twilioNumbers.map((number, index) => (
                    <option key={index} value={number.phoneNumber}>
                      {number.phoneNumber}
                    </option>
                  ))}
                </select>
              </div>

              {/* Show Filter type only when a number is selected */}
              {selectedNumber && (
                <>
                  <div>
                    <label htmlFor="filter-type">Select Filter: </label>
                    <select
                      id="filter-type"
                      value={filterType}
                      onChange={(e) => setFilterType(e.target.value)}
                      className="dropdown"
                    >
                      <option value="all">All Logs</option>
                      <option value="byDate">By Date</option>
                    </select>
                  </div>

                  {/* Show From and To date fields if "By Date" is selected */}
                  {filterType === "byDate" && (
                    <>
                      <div>
                        <label htmlFor="from-date">From: </label>
                        <input
                          type="date"
                          id="from-date"
                          value={fromDate}
                          onChange={(e) => setFromDate(e.target.value)}
                        />
                      </div>

                      <div>
                        <label htmlFor="to-date">To: </label>
                        <input
                          type="date"
                          id="to-date"
                          value={toDate}
                          onChange={(e) => setToDate(e.target.value)}
                        />
                      </div>
                    </>
                  )}
                </>
              )}
            </div>

            <div className="buttons-container">
              {/* Buttons to fetch logs and clear */}
              <button className="fetch-logs-button" onClick={fetchMessageLogs}>
                Create Report
              </button>
              <button className="clear-button" onClick={clearFilters}>
                Clear
              </button>
            </div>
          </div>
        ) : (
          <p>No Twilio numbers assigned.</p>
        )}

        {/* Display message logs */}
        {loading ? (
          <p>Loading message logs...</p>
        ) : (
          messageLogs.length > 0 ? (
            <>
              <div className="message-stats">
                <div className="stats-box">
                  <h4>Total Sent</h4>
                  <p>{stats.sent}</p>
                </div>
                <div className="stats-box">
                  <h4>Total Received</h4>
                  <p>{stats.received}</p>
                </div>
                <div className="stats-box">
                  <h4>Delivered Percentage</h4>
                  <p>
                    {stats.delivered}/{stats.sent} ({stats.sent > 0 ? Math.round((stats.delivered / stats.sent) * 100) : 0}%)
                  </p>
                </div>
                <div className="stats-box">
                  <h4>Failed Messages</h4>
                  <p>{stats.failed}</p>
                </div>
              </div>

              <table className="message-log-table">
                <thead>
                  <tr>
                    <th>Day & Time</th>
                    <th>Phone Number</th>
                    <th>Message</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {messageLogs.map((log, index) => (
                    <tr key={index}>
                      <td>{new Date(log.dateSent).toLocaleString()}</td>
                      <td>{log.from}</td>
                      <td>
                        <div className="message-content">{log.body}</div>
                      </td>
                      <td>
                        <button onClick={() => openReplyModal(log.from)}>Reply</button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          ) : (
            <p>No message logs found for {selectedNumber}.</p>
          )
        )}

        {error && <p className="error-message">{error}</p>}

        {/* Modal for sending reply */}
        {showReplyModal && (
          <>
            <div className="modal-overlay"></div> {/* Semi-transparent overlay */}
            <div className="modal">
              <div className="modal-content">
                <h3>Reply to {replyToNumber}</h3>
                <textarea
                  value={replyMessage}
                  onChange={(e) => setReplyMessage(e.target.value)}
                  placeholder="Enter your message here"
                />
                <div className="modal-actions">
                  <button onClick={sendReplyMessage}>Send</button>
                  <button onClick={() => setShowReplyModal(false)}>Cancel</button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </AdminLayout>
  );
};

export default AdminPanel;
