import React, { useState, useEffect } from "react";
import { getAuth, updatePassword, updateEmail } from "firebase/auth";
import { getFirestore, doc, getDoc, updateDoc } from "firebase/firestore";
import AdminLayout from "./AdminLayout";  // Import the layout with sidebar
import '../css/AdminProfile.css';  // Add styles for the profile page

const AdminProfile = () => {
  const [adminDetails, setAdminDetails] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    newPassword: '',
  });
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const auth = getAuth();
  const db = getFirestore();
  
  useEffect(() => {
    const fetchAdminData = async () => {
      const user = auth.currentUser;
      if (user) {
        const docRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setAdminDetails((prev) => ({
            ...prev,
            firstName: docSnap.data().firstName,
            lastName: docSnap.data().lastName,
            email: user.email,
          }));
        }
      }
    };
    fetchAdminData();
  }, [auth, db]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAdminDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleUpdateDetails = async (e) => {
    e.preventDefault();
    try {
      const user = auth.currentUser;
      const docRef = doc(db, "users", user.uid);
      await updateDoc(docRef, {
        firstName: adminDetails.firstName,
        lastName: adminDetails.lastName,
      });

      if (user.email !== adminDetails.email) {
        await updateEmail(user, adminDetails.email);
      }

      setMessage("Profile updated successfully.");
      setError("");
    } catch (error) {
      setError("Failed to update profile.");
      setMessage("");
    }
  };

  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    if (adminDetails.newPassword !== "") {
      try {
        const user = auth.currentUser;
        await updatePassword(user, adminDetails.newPassword);
        setMessage("Password updated successfully.");
        setError("");
      } catch (error) {
        setError("Failed to update password.");
        setMessage("");
      }
    } else {
      setError("Please enter a new password.");
    }
  };

  return (
    <AdminLayout>  {/* Sidebar will appear here */}
      <div className="admin-profile">
        <h2>Admin Profile</h2>
        {message && <p className="success-message">{message}</p>}
        {error && <p className="error-message">{error}</p>}
        <form onSubmit={handleUpdateDetails}>
          <div className="form-group">
            <label>First Name</label>
            <input
              type="text"
              name="firstName"
              value={adminDetails.firstName}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Last Name</label>
            <input
              type="text"
              name="lastName"
              value={adminDetails.lastName}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              name="email"
              value={adminDetails.email}
              onChange={handleInputChange}
              required
            />
          </div>
          <button type="submit" className="update-button">Update Profile</button>
        </form>

        <h3>Change Password</h3>
        <form onSubmit={handleUpdatePassword}>
          <div className="form-group">
            <label>New Password</label>
            <input
              type="password"
              name="newPassword"
              value={adminDetails.newPassword}
              onChange={handleInputChange}
              required
            />
          </div>
          <button type="submit" className="update-button">Update Password</button>
        </form>
      </div>
    </AdminLayout>
  );
};

export default AdminProfile;
