import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import AdminLogin from "./admin/components/AdminLogin";
import AdminPanel from "./admin/AdminPanel";
import ManageUsers from "./admin/components/ManageUsers";
import AdminProfile from "./admin/components/AdminProfile";
import TwilioNumbers from "./admin/components/TwilioNumbers";
import UserLogin from "./user/components/UserLogin";
import UserDashboard from "./user/components/UserDashboard";
import UserProfile from "./user/components/UserProfile";  // Import the UserProfile component
import UserNumbers from "./user/components/UserNumbers";  // Import the new UserNumbers component
import Home from "./Home";
import { getAuth, onAuthStateChanged, setPersistence, browserLocalPersistence } from "firebase/auth";

function App() {
  const [adminLoggedIn, setAdminLoggedIn] = useState(false);
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const auth = getAuth();

  useEffect(() => {
    setPersistence(auth, browserLocalPersistence)
      .then(() => {
        onAuthStateChanged(auth, (user) => {
          if (user) {
            const adminUser = sessionStorage.getItem("adminUser");
            const normalUser = sessionStorage.getItem("user");

            if (adminUser) {
              setAdminLoggedIn(true);
              setUserLoggedIn(false);
            } else if (normalUser) {
              setUserLoggedIn(true);
              setAdminLoggedIn(false);
            }
          } else {
            setAdminLoggedIn(false);
            setUserLoggedIn(false);
            sessionStorage.removeItem("adminUser");
            sessionStorage.removeItem("user");
          }
          setLoading(false);
        });
      })
      .catch((error) => {
        console.error("Error setting persistence:", error);
        setLoading(false);
      });
  }, [auth]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />

        {/* Admin routes */}
        <Route path="/admin/login" element={<AdminLogin />} />
        <Route path="/admin/panel" element={adminLoggedIn ? <AdminPanel /> : <Navigate to="/admin/login" />} />
        <Route path="/admin/users" element={adminLoggedIn ? <ManageUsers /> : <Navigate to="/admin/login" />} />
        <Route path="/admin/profile" element={adminLoggedIn ? <AdminProfile /> : <Navigate to="/admin/login" />} />
        <Route path="/admin/numbers" element={adminLoggedIn ? <TwilioNumbers /> : <Navigate to="/admin/login" />} />

        {/* User routes */}
        <Route path="/user/login" element={<UserLogin />} />
        <Route path="/user/dashboard" element={userLoggedIn ? <UserDashboard /> : <Navigate to="/user/login" />} />
        <Route path="/user/profile" element={userLoggedIn ? <UserProfile /> : <Navigate to="/user/login" />} />  {/* User profile route */}
        <Route path="/user/numbers" element={userLoggedIn ? <UserNumbers /> : <Navigate to="/user/login" />} />

      </Routes>
    </Router>
  );
}

export default App;
