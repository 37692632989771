import React from "react";
import UserSidebar from "./UserSidebar";  // Make sure this path is correct
import '../css/UserLayout.css';  // Add styles for the layout

const UserLayout = ({ children }) => {
  return (
    <div className="user-layout">
      <UserSidebar />
      <div className="user-content">
        {children}
      </div>
    </div>
  );
};

export default UserLayout;
