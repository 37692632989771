import React from "react";
import { useNavigate, Link } from "react-router-dom";  // Import Link for routing
import '../css/Sidebar.css';  // Import your sidebar CSS
import logo from '../../assets/NSGroupLogo2.png';  // Use the provided NSGroup logo

// Import icons (example using Font Awesome icons)
import { FaCalendar, FaBullhorn, FaUsers, FaTags, FaSignOutAlt, FaTachometerAlt } from 'react-icons/fa';

const Sidebar = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    sessionStorage.removeItem("adminUser");
    navigate("/admin/login", { replace: true });
    window.location.reload();  // Refresh after logout
  };

  return (
    <div className="sidebar">
      {/* Add the logo at the top of the sidebar */}
      <div className="sidebar-logo">
        <img src={logo} alt="NSGroup Logo" className="logo" />
      </div>
      
      <ul className="sidebar-menu">
        {/* Link to Admin Dashboard */}
        <li className="sidebar-item">
          <Link to="/admin/panel" className="sidebar-link">
            <FaTachometerAlt className="menu-icon" /> Dashboard
          </Link>
        </li>

        {/* Link to Manage Users */}
        <li className="sidebar-item">
          <Link to="/admin/users" className="sidebar-link">
            <FaUsers className="menu-icon" /> Manage Users
          </Link>
        </li>

        {/* Link to Twilio Numbers Page */}
        <li className="sidebar-item">
          <Link to="/admin/numbers" className="sidebar-link">
            <FaBullhorn className="menu-icon" /> Numbers
          </Link>
        </li>

        {/* Logout */}
        <li className="sidebar-item logout" onClick={handleLogout}>
          <FaSignOutAlt className="menu-icon" /> Logout
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
