import React from "react";
import { useNavigate, Link } from "react-router-dom";
import '../css/UserSidebar.css';
import logo from '../../assets/NSGroupLogo2.png';  // Updated logo

// Importing available icons from Font Awesome
import { FaUser, FaTachometerAlt, FaBullhorn, FaSignOutAlt } from "react-icons/fa";

const UserSidebar = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    sessionStorage.removeItem("user");
    navigate("/user/login");
  };

  return (
    <div className="user-sidebar">
      <div className="sidebar-logo">
        <img src={logo} alt="NSGroup Logo" className="logo" />
      </div>
      <ul className="user-sidebar-menu">
        <li className="user-sidebar-item">
          <Link to="/user/dashboard" className="user-sidebar-link">
            <FaTachometerAlt className="menu-icon" /> Dashboard
          </Link>
        </li>
        <li className="user-sidebar-item">
          <Link to="/user/profile" className="user-sidebar-link">
            <FaUser className="menu-icon" /> Profile
          </Link>
        </li>
        <li className="user-sidebar-item">
          <Link to="/user/numbers" className="user-sidebar-link">
            <FaBullhorn className="menu-icon" /> Numbers
          </Link>
        </li>
        <li className="user-sidebar-item" onClick={handleLogout}>
          <FaSignOutAlt className="menu-icon" /> Logout
        </li>
      </ul>
    </div>
  );
};

export default UserSidebar;
