import React, { useEffect, useState } from "react";
import { getFirestore, collection, getDocs, query, where } from "firebase/firestore";
import UserLayout from "./UserLayout";  // Ensure this is the correct path to the user layout
import "../css/UserNumbers.css";  // Create this CSS file for styling

const UserNumbers = () => {
  const [assignedNumbers, setAssignedNumbers] = useState([]);
  const db = getFirestore();

  // Fetch assigned numbers for the logged-in user
  useEffect(() => {
    const fetchAssignedNumbers = async () => {
      const user = JSON.parse(sessionStorage.getItem("user"));  // Fetch user from sessionStorage
      console.log("Logged-in User:", user);

      if (user && user.email) {  // Adjusted to query by email for testing
        try {
          // Query Firestore to match the assignedUser.email field (for testing)
          const q = query(collection(db, "twilioNumbers"), where("assignedUser.email", "==", user.email));
          const querySnapshot = await getDocs(q);

          if (!querySnapshot.empty) {
            const numbers = querySnapshot.docs.map((doc) => doc.data());
            console.log("Assigned Numbers:", numbers);
            setAssignedNumbers(numbers);
          } else {
            console.log("No numbers assigned to this user.");
          }
        } catch (error) {
          console.error("Error fetching assigned numbers:", error);
        }
      }
    };

    fetchAssignedNumbers();
  }, [db]);

  return (
    <UserLayout>
      <div className="user-numbers">
        <h2>Assigned Numbers</h2>
        {assignedNumbers.length > 0 ? (
          <table className="numbers-table">
            <thead>
              <tr>
                <th>Number</th>
                <th>Date Assigned</th>
              </tr>
            </thead>
            <tbody>
              {assignedNumbers.map((number, index) => (
                <tr key={index}>
                  <td>{number.phoneNumber}</td>
                  <td>{number.dateCreated && new Date(number.dateCreated).toLocaleDateString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No numbers assigned.</p>
        )}
      </div>
    </UserLayout>
  );
};

export default UserNumbers;
