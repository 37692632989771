import React, { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebaseConfig";
import { useNavigate } from "react-router-dom";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import '../css/AdminLogin.css';  // Import the CSS
import logo from '../../assets/logo.png';  // Import the logo

const AdminLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const db = getFirestore();

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    try {
      // Sign in the user
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Fetch user data from Firestore
      const userDoc = doc(db, "users", user.uid);
      const userSnapshot = await getDoc(userDoc);

      if (userSnapshot.exists()) {
        const userData = userSnapshot.data();
        if (userData.role === "admin") {
          sessionStorage.setItem("adminUser", JSON.stringify(userData));

          // Delay navigation slightly to ensure sessionStorage is set
          setTimeout(() => {
            navigate("/admin/panel");
            window.location.reload();  // Optional: force a reload after navigation
          }, 200);
        } else {
          setError("Unauthorized access. You are not an admin.");
        }
      } else {
        setError("No such user found in the database.");
      }
    } catch (error) {
      setError("Invalid email or password.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="admin-login-page">
      <div className="admin-login-container">
        <img src={logo} alt="Admin Logo" className="admin-login-logo" />
        <h2>Admin Login</h2>
        {error && <p className="admin-error-message">{error}</p>}
        <form onSubmit={handleLogin}>
          <div className="admin-form-group">
            <label>Email</label>
            <input 
              type="email" 
              value={email} 
              onChange={(e) => setEmail(e.target.value)} 
              required 
              className="admin-login-input"
            />
          </div>
          <div className="admin-form-group">
            <label>Password</label>
            <input 
              type="password" 
              value={password} 
              onChange={(e) => setPassword(e.target.value)} 
              required 
              className="admin-login-input"
            />
          </div>
          <button type="submit" className="admin-login-button" disabled={loading}>
            {loading ? "Logging in..." : "Login"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default AdminLogin;
