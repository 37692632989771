import React, { useEffect, useState } from "react";
import { getFirestore, collection, getDocs, deleteDoc, doc } from "firebase/firestore";
import AdminLayout from "./AdminLayout";
import CreateUser from "./CreateUser";  // Import the CreateUser component
import "../css/ManageUsers.css";

const ManageUsers = () => {
  const [users, setUsers] = useState([]);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [userToEdit, setUserToEdit] = useState(null);
  const [userToDelete, setUserToDelete] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const db = getFirestore();

  useEffect(() => {
    const fetchUsers = async () => {
      const querySnapshot = await getDocs(collection(db, "users"));
      const userList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setUsers(userList.filter(user => user.role === "user"));  // Display only users, not admins
    };

    fetchUsers();
  }, [db]);

  // Handle opening and closing modals
  const handleCreateModal = () => setShowCreateModal(true);
  const handleEditModal = (user) => {
    setUserToEdit(user);
    setShowEditModal(true);
  };
  const handleDeleteConfirmation = (user) => {
    setUserToDelete(user);
    setShowDeleteConfirmation(true);
  };

  const handleDeleteUser = async () => {
    if (userToDelete) {
      await deleteDoc(doc(db, "users", userToDelete.id));
      setUsers(users.filter(user => user.id !== userToDelete.id));  // Update the list after deletion
      setShowDeleteConfirmation(false);
      setUserToDelete(null);
    }
  };

  return (
    <AdminLayout>
      <div className="manage-users">
        <div className="manage-users-container">
          <h2>Manage Users</h2>
          <button className="create-user" onClick={handleCreateModal}>Create User</button>

          <table className="user-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Phone Number</th>
                <th>Street</th>
                <th>City</th>
                <th>State</th>
                <th>Zip Code</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {users.map(user => (
                <tr key={user.id} className={user.firstName === 'TEST' ? '' : ''}>
                  <td>{user.firstName} {user.lastName}</td>
                  <td>{user.email}</td>
                  <td>{user.phoneNumber}</td>
                  <td>{user.street}</td>
                  <td>{user.city}</td>
                  <td>{user.state}</td>
                  <td>{user.zipCode}</td>
                  <td>
                    <button onClick={() => handleEditModal(user)}>Edit</button>
                    <button onClick={() => handleDeleteConfirmation(user)}>Delete</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Create User Modal */}
        {showCreateModal && <CreateUser closeModal={() => setShowCreateModal(false)} />}

        {/* Edit User Modal */}
        {showEditModal && (
          <CreateUser user={userToEdit} closeModal={() => setShowEditModal(false)} />
        )}

        {/* Delete Confirmation Modal */}
        {showDeleteConfirmation && (
          <div className="delete-confirmation">
            <p>Are you sure you want to delete this user?</p>
            <button onClick={handleDeleteUser}>Yes</button>
            <button onClick={() => setShowDeleteConfirmation(false)}>No</button>
          </div>
        )}
      </div>
    </AdminLayout>
  );
};

export default ManageUsers;
