import React, { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebaseConfig";
import { useNavigate } from "react-router-dom";
import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";
import '../css/UserLogin.css';
import logo from '../../assets/logo.png';  // Import the logo

const UserLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false); // Add a loading state
  const navigate = useNavigate();
  const db = getFirestore();

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(""); // Clear previous errors

    try {
      // Sign in the user with email and password
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Query Firestore by UID field instead of using document ID
      const q = query(collection(db, "users"), where("uid", "==", user.uid));  // Search by uid field
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        // Get the first matching document
        const userData = querySnapshot.docs[0].data();

        // Check if the role is "admin" and prevent login if not allowed
        if (userData.role === "admin") {
          setError("Admins are not allowed to log in here.");
        } else {
          // Store session for the regular user
          sessionStorage.setItem("user", JSON.stringify(userData));

          // Navigate to user dashboard after setting session
          setTimeout(() => {
            navigate("/user/dashboard");
            window.location.reload();  // Optional: Force a reload
          }, 200);  // Short delay to ensure sessionStorage is set
        }
      } else {
        setError("No such user found.");  // Firestore doc not found
      }
    } catch (error) {
      setError("Invalid email or password.");
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <div className="user-login-page">
      <div className="user-login-container">
        <img src={logo} alt="User Logo" className="user-login-logo" />
        <h2>User Login</h2>
        {error && <p className="user-login-error-message">{error}</p>}
        <form onSubmit={handleLogin}>
          <div className="user-login-form-group">
            <label>Email</label>
            <input 
              type="email" 
              value={email} 
              onChange={(e) => setEmail(e.target.value)} 
              required 
              className="user-login-input"
            />
          </div>
          <div className="user-login-form-group">
            <label>Password</label>
            <input 
              type="password" 
              value={password} 
              onChange={(e) => setPassword(e.target.value)} 
              required 
              className="user-login-input"
            />
          </div>
          <button type="submit" className="user-login-button" disabled={loading}>
            {loading ? "Logging in..." : "Login"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default UserLogin;
