import React, { useState, useEffect } from "react";
import { getAuth, updatePassword, updateEmail } from "firebase/auth";
import { getFirestore, collection, query, where, getDocs, updateDoc } from "firebase/firestore";
import UserLayout from "./UserLayout";  // Correct path for the user layout
import '../css/UserProfile.css';  // Add styles for the profile page

const UserProfile = () => {
  const [userDetails, setUserDetails] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    street: '',
    city: '',
    state: '',
    zipCode: '',
    newPassword: '',
  });
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const auth = getAuth();
  const db = getFirestore();

  // Fetch the user data from Firestore on component mount
  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        try {
          // Query Firestore using the uid field, as the document ID is different
          const q = query(collection(db, "users"), where("uid", "==", user.uid));
          const querySnapshot = await getDocs(q);
          
          if (!querySnapshot.empty) {
            const userDoc = querySnapshot.docs[0]; // Get the first matching document
            const data = userDoc.data();
            setUserDetails((prev) => ({
              ...prev,
              firstName: data.firstName || '',
              lastName: data.lastName || '',
              email: data.email || user.email || '',
              phoneNumber: data.phoneNumber || '',
              street: data.street || '',
              city: data.city || '',
              state: data.state || '',
              zipCode: data.zipCode || ''
            }));
          } else {
            setError("User data not found in Firestore.");
          }
        } catch (err) {
          console.error("Error fetching user data:", err);
          setError("Failed to fetch user data.");
        }
      }
    };
    fetchUserData();
  }, [auth, db]);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handle updating user details
  const handleUpdateDetails = async (e) => {
    e.preventDefault();
    try {
      const user = auth.currentUser;

      // Query to find the document by the uid field and update the relevant data
      const q = query(collection(db, "users"), where("uid", "==", user.uid));
      const querySnapshot = await getDocs(q);
      
      if (!querySnapshot.empty) {
        const userDoc = querySnapshot.docs[0];
        const docRef = userDoc.ref;

        await updateDoc(docRef, {
          firstName: userDetails.firstName,
          lastName: userDetails.lastName,
          phoneNumber: userDetails.phoneNumber,
          street: userDetails.street,
          city: userDetails.city,
          state: userDetails.state,
          zipCode: userDetails.zipCode
        });

        if (user.email !== userDetails.email) {
          await updateEmail(user, userDetails.email);
        }

        setMessage("Profile updated successfully.");
        setError("");
      }
    } catch (error) {
      setError("Failed to update profile.");
      setMessage("");
    }
  };

  // Handle updating password
  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    if (userDetails.newPassword !== "") {
      try {
        const user = auth.currentUser;
        await updatePassword(user, userDetails.newPassword);
        setMessage("Password updated successfully.");
        setError("");
      } catch (error) {
        setError("Failed to update password.");
        setMessage("");
      }
    } else {
      setError("Please enter a new password.");
    }
  };

  return (
    <UserLayout>
      <div className="user-profile">
        <h2>User Profile</h2>
        {message && <p className="success-message">{message}</p>}
        {error && <p className="error-message">{error}</p>}
        
        <form onSubmit={handleUpdateDetails} className="form-grid">
          {/* First Name and Last Name on one row */}
          <div className="form-group">
            <label>First Name</label>
            <input
              type="text"
              name="firstName"
              value={userDetails.firstName}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Last Name</label>
            <input
              type="text"
              name="lastName"
              value={userDetails.lastName}
              onChange={handleInputChange}
              required
            />
          </div>

          {/* Email and Phone Number on one row */}
          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              name="email"
              value={userDetails.email}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Phone Number</label>
            <input
              type="text"
              name="phoneNumber"
              value={userDetails.phoneNumber}
              onChange={handleInputChange}
              required
            />
          </div>

          {/* Street on one row */}
          <div className="form-group full-width">
            <label>Street</label>
            <input
              type="text"
              name="street"
              value={userDetails.street}
              onChange={handleInputChange}
              required
            />
          </div>

          {/* City, State, and Zip Code on the next row */}
          <div className="form-grid-three">
            <div className="form-group">
              <label>City</label>
              <input
                type="text"
                name="city"
                value={userDetails.city}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label>State</label>
              <input
                type="text"
                name="state"
                value={userDetails.state}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Zip Code</label>
              <input
                type="text"
                name="zipCode"
                value={userDetails.zipCode}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>

          <button type="submit" className="update-button">Update Profile</button>
        </form>

        <h3>Change Password</h3>
        <form onSubmit={handleUpdatePassword}>
          <div className="form-group full-width">
            <label>New Password</label>
            <input
              type="password"
              name="newPassword"
              value={userDetails.newPassword}
              onChange={handleInputChange}
              required
            />
          </div>
          <button type="submit" className="update-button">Update Password</button>
        </form>
      </div>
    </UserLayout>
  );
};

export default UserProfile;
