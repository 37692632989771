import React from "react";
import { Link } from "react-router-dom";
import './css/Home.css';  // Updated CSS file
import logo from './assets/logo.png';  // Import the company logo


const Home = () => {
  return (
    <div className="home-page">
      <div className="home-container">
        {/* Display the logo here */}
        <img src={logo} alt="Company Logo" className="company-logo" />
        <h2>Login As</h2>  {/* Add the heading */}
        <div className="login-box">
          <Link to="/admin/login">
            <button className="login-button">Admin</button>
          </Link>
          <Link to="/user/login">
            <button className="login-button">User</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Home;
