import React, { useEffect, useState } from "react";
import axios from "axios";  // Axios for making API calls
import { getFirestore, collection, getDocs, doc, setDoc, updateDoc, getDoc } from "firebase/firestore";  // Firestore imports
import AdminLayout from "./AdminLayout";
import '../css/TwilioNumbers.css';  // Import the CSS file for styling

const TwilioNumbers = () => {
  const [numbers, setNumbers] = useState([]);  // Twilio numbers from backend
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);  // Firebase users
  const [assigningNumber, setAssigningNumber] = useState(null);  // Track number being assigned
  const [selectedUser, setSelectedUser] = useState({});  // Store selected user for each number

  const db = getFirestore();  // Initialize Firestore

  // Fetch Firebase users and filter out admins
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "users"));
        const usersList = querySnapshot.docs
          .map((doc) => ({ id: doc.id, ...doc.data() }))
          .filter(user => user.role === "user");  // Filter only users with role "user"
        setUsers(usersList);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching users:", error);
        setLoading(false);
      }
    };

    fetchUsers();
  }, [db]);

  // Fetch Twilio numbers and assigned users from Firestore
  useEffect(() => {
    const fetchTwilioNumbers = async () => {
      try {
        // Updated API URL to point to the deployed backend on Vercel
        const response = await axios.get("https://serv2.mycampaignkit.com/api/twilio/numbers");  // Updated API URL
        const numbersList = response.data;

        // Now let's check Firestore for assigned users for these numbers
        const assignedNumbers = await Promise.all(
          numbersList.map(async (number) => {
            const docRef = doc(db, "twilioNumbers", number.sid);
            const docSnapshot = await getDoc(docRef);
            if (docSnapshot.exists()) {
              const data = docSnapshot.data();
              return {
                ...number,
                assignedUser: data.assignedUser || null,  // Get assigned user if exists
              };
            }
            return number;
          })
        );

        setNumbers(assignedNumbers);  // Set numbers with assigned users in state
        setLoading(false);
      } catch (error) {
        console.error("Error fetching Twilio numbers:", error);
        setLoading(false);
      }
    };

    fetchTwilioNumbers();
  }, [db]);

  const handleAssign = (number) => {
    setAssigningNumber(number);
  };

  // Assign a Twilio number to a user and save in Firestore
  const handleUserSelection = async (userId, number) => {
    const user = users.find((u) => u.id === userId);

    if (user) {
      try {
        // Save the Twilio number and assigned user in Firestore
        const numberRef = doc(db, "twilioNumbers", number.sid);

        await setDoc(numberRef, {
          phoneNumber: number.phoneNumber,
          dateCreated: number.dateCreated,
          assignedUser: {
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email
          }
        }, { merge: true });  // Merge true so we don't overwrite any existing fields

        // Update the selected user for this number in local state
        const updatedNumbers = numbers.map((num) => {
          if (num.sid === number.sid) {
            return {
              ...num,
              assignedUser: {
                id: user.id,
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
              },
            };
          }
          return num;
        });
        setNumbers(updatedNumbers);
        setAssigningNumber(null);  // Close the popup after user is selected
      } catch (error) {
        console.error("Error assigning number to user:", error);
      }
    }
  };

  // Unassign a Twilio number (remove the assigned user)
  const handleUnassign = async (number) => {
    try {
      const numberRef = doc(db, "twilioNumbers", number.sid);
      await updateDoc(numberRef, {
        assignedUser: null,  // Remove the assigned user
      });

      // Update the state to reflect the unassigned user
      const updatedNumbers = numbers.map((num) => {
        if (num.sid === number.sid) {
          return {
            ...num,
            assignedUser: null,  // Remove the assigned user
          };
        }
        return num;
      });
      setNumbers(updatedNumbers);
    } catch (error) {
      console.error("Error unassigning number:", error);
    }
  };

  if (loading) return <div>Loading numbers and users...</div>;

  return (
    <AdminLayout>
      <div className="main-content">
        <div className="twilio-numbers">
          <table className="twilio-table">
            <thead>
              <tr className="twilio-heading-row">
                <th colSpan="3">Twilio Numbers</th> {/* Heading spans across all 3 columns */}
              </tr>
              <tr>
                <th>Number</th>
                <th>Created Date</th>
                <th>Assign/Unassign</th>
              </tr>
            </thead>
            <tbody>
              {numbers.map((number) => (
                <tr key={number.sid}>
                  <td>{number.phoneNumber}</td>
                  <td>{new Date(number.dateCreated).toLocaleDateString()}</td>
                  <td>
                    {number.assignedUser ? (
                      <>
                        <button
                          className="unassign-button"
                          onClick={() => handleUnassign(number)}
                        >
                          Unassign {number.assignedUser.firstName}
                        </button>
                      </>
                    ) : (
                      <>
                        {assigningNumber === number ? (
                          <UserSelectionPopup
                            users={users}
                            number={number}
                            onSelectUser={handleUserSelection}
                            onCancel={() => setAssigningNumber(null)}
                          />
                        ) : (
                          <button
                            className="assign-button"
                            onClick={() => handleAssign(number)}
                          >
                            Assign
                          </button>
                        )}
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </AdminLayout>
  );
};

const UserSelectionPopup = ({ users, number, onSelectUser, onCancel }) => {
  const [selectedUserId, setSelectedUserId] = useState('');

  return (
    <div className="popup">
      <div className="popup-content">
        <h3>Select a User for {number.phoneNumber}</h3>
        <select
          className="user-dropdown"
          value={selectedUserId}
          onChange={(e) => setSelectedUserId(e.target.value)}
        >
          <option value="" disabled>Select a user</option>
          {users.map((user) => (
            <option key={user.id} value={user.id}>
              {user.firstName} {user.lastName}
            </option>
          ))}
        </select>
        <div className="popup-buttons">
          <button
            className="assign-button"
            onClick={() => onSelectUser(selectedUserId, number)}
            disabled={!selectedUserId}
          >
            Assign
          </button>
          <button className="cancel-button" onClick={onCancel}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default TwilioNumbers;
